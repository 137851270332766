/**
***  global-navigation  */

$(function(){

//////////////////////////////////////////////////////////////////////
// mobile
//////////////////////////////////////////////////////////////////////

//メニューを開く
$('#open-menu').on('click', function(){
  $('body').addClass('open-menu');
  $('#navigation').stop().slideDown(300);
});

//メニューを閉じる
$('#close-menu').on('click', function(){
  $('body').removeClass('open-menu');
  $('#navigation').stop().slideUp(300);
  $('.sub-menu-wrapper').slideUp(300);
  $('#navigation .trigger').removeClass('open');
});
$(document).click(function(event) {
  const w = window.innerWidth;
  if(w < 980 && !$(event.target).closest('header').length) {
    $('body').removeClass('open-menu');
    $('#navigation').stop().slideUp(300);
    $('.sub-menu-wrapper').slideUp(300);
    $('#navigation .trigger').removeClass('open');
  } 
});

$('.open-menu').on('click', function(){
  $('body').removeClass('open-menu');
  $('#navigation').stop().slideUp(300);
  $('.sub-menu-wrapper').slideUp(300);
  $('#navigation .trigger').removeClass('open');
});

//子メニューの表示・非表示
$('#navigation .trigger').on('click', function(e){
  e.preventDefault();
  const w = window.innerWidth;
  if(w < 980){
    const target = $(this).parent().next('.sub-menu-wrapper');
    $(target).stop().slideToggle(300);
    $('#navigation').find('.sub-menu-wrapper').not(target).slideUp(300);
    $('#navigation').find('.trigger').not(this).removeClass('open');
    $(this).toggleClass('open');
  }
});

//////////////////////////////////////////////////////////////////////
// pc
//////////////////////////////////////////////////////////////////////

const parentList = ".global-menu > li";
const childList = ".sub-menu-container > li";

//子メニュー表示非表示
$(parentList).on('mouseenter', function() {
  const w = window.innerWidth;
  if(w >= 980){
    $(this).children('.sub-menu-wrapper').stop().slideDown(300);
    $(this).addClass('active');
  } 
});
$(parentList).on('mouseleave', function() {
  const w = window.innerWidth;
  if(w >= 980){
    $(this).children('.sub-menu-wrapper').stop().slideUp(100);
    $(this).removeClass('active');
  }
});

// //ホバー時にクラス付与
function inActive(target){
  $(target).on('mouseenter', function() {
    const w = window.innerWidth;
    if(w >= 980){
      $(target).not(this).addClass('inactive');
    } 
  });
  $(target).on('mouseleave', function() {
    const w = window.innerWidth;
    if(w >= 980){
      $(target).not(this).removeClass('inactive');
    }
  });
}

inActive(parentList);
inActive(childList);

var gnavOffset = $("#navigation").offset().top;

//スクロールでfix
$(window).on('load scroll', function() {
  const w = window.innerWidth;
  if(w >= 980){
    var scroll = $(this).scrollTop();
    if (scroll > gnavOffset) {
      $("header").addClass('fixed');
    }else{
      $("header").removeClass('fixed');
    }
  }
});

//////////////////////////////////////////////////////////////////////
// reset
//////////////////////////////////////////////////////////////////////

$(window).on('load resize', function() {
  $('#navigation .sub-menu-wrapper').attr('style', 'display: none;');
  $('#navigation .trigger').removeClass('open');
  $('body').removeClass('open-menu');
  const w = window.innerWidth;
    if(w < 980){
      $('#navigation').slideUp(100);
    }else{
      $('#navigation').show();
    }
});

//////////////////////////////////////////////////////////////////////
// floatingBnr
//////////////////////////////////////////////////////////////////////

$(document).ready(function(){
  floatingBnr();
});

$(window).on('resize', function() {
  floatingBnr();
});

function floatingBnr(){
  var ww = $(window).width();
  var content = 1540;
  if( ww >= content){
    $('.floating-bnr-wrapper').addClass('horizontal');
  }else{
    $('.floating-bnr-wrapper').removeClass('horizontal');
  }
}

});